// start with only pages displayed
const initState = {
  page: '*',
  type: '',
  image: '',
  fromCategory: '',
};
// if fromCategory is 'image' Vip.js will not fade row section
const funcToExport = (state = initState, action) => {
  switch (true) {
    case action.type === 'ROW_CONTROL':
      let payload = action.payload;
      console.log('payload: ', payload);
      return payload;
    default:
      return state;
  }
};
export default funcToExport;
