const initState = { source: {}, target: {}, lastMouse: '' };
const funcToExport = (state = initState, action) => {
  switch (action.type) {
    case 'MOVE_ROW':
      let moveObjAdj = JSON.parse(JSON.stringify(state));
      console.log('action.payload.source: ', action.payload.source);
      console.log('action.payload.target: ', action.payload.target);
      moveObjAdj.lastMouse = action.payload.lastMouse;
      if (action.payload.source) {
        moveObjAdj.source = action.payload.source;
      } else if (action.payload.target) {
        moveObjAdj.target = action.payload.target;
      }
      return moveObjAdj;
    case 'MOVE_ROW_CLEAR':
      console.log('action.payload: ', action.payload);
      return initState;
    default:
      return state;
  }
};
export default funcToExport;
