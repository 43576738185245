import { combineReducers } from 'redux';
import faBodyReducer from './faBodyObjReducer';
import footerReducer from './footerObjReducer';
import moveRowReducer from './moveRowReducer';
import pagesReducer from './pagesObjReducer';
import pagesPreviewReducer from './pagesPreviewObjReducer';
import rowControlReducer from './rowControlReducer';
import settingsReducer from './settingsObjReducer';
import tabChoiceReducer from './tabChoiceReducer';
import typesImageViewedReducer from './typesImageViewedObjReducer';
// import typeOrdrReducer from './typeOrdrReducer';

const rootReducer = combineReducers({
  faBodyObj: faBodyReducer,
  footerObj: footerReducer,
  moveRowObj: moveRowReducer,
  pagesObj: pagesReducer,
  pagesPreviewObj: pagesPreviewReducer,
  rowControlObj: rowControlReducer,
  settingsObj: settingsReducer,
  tabChoice: tabChoiceReducer,
  typesImageViewedObj: typesImageViewedReducer,
  // typeOrdr: typeOrdrReducer,
});

export default rootReducer;
