// import { updateSortOrdrArrays } from '../pages/Vip/vipFunctions.js';
const initState = {};
const funcToExport = (state = initState, action) => {
  // console.log('state: ', state);
  switch (action.type) {
    case 'PAGES_PREVIEW_OBJECT':
      // used to initialize complete preview object
      // console.log('state: ', JSON.parse(JSON.stringify(state)));
      // console.log(
      //   'action.payload PAGES_PREVIEW_OBJECT: ',
      //   JSON.parse(JSON.stringify(action.payload))
      // );
      return action.payload;
    default:
      return state;
  }
};
export default funcToExport;
