// built using v5 of react-router-dom
import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import './app.css';

// development variables are established by the npm "start" script
// staging/production variables are pm2-created

const Footer = lazy(() => import('./utility/Footer/Footer'));
const Home = lazy(() => import('./pages/Home/Home'));
const MultiTypes = lazy(() => import('./pages/MultiType/MultiType'));
const MultiTypesImg = lazy(() => import('./pages/MultiTypeImg/MultiTypeImg'));
const SetupStore = lazy(() => import('./utility/SetupStore/SetupStore'));
const Single = lazy(() => import('./pages/Single/Single'));
const Vip = lazy(() => import('./pages/Vip/Vip'));

function App() {
  return (
    <Router>
      <Suspense fallback={''}>
        <Route path='/:pageName?/:pageId?' component={SetupStore} />
        <Route exact path='/' component={Home} />
        <Route exact path='/home/:page_id' component={Home} />
        <Route
          exact
          path='/multiple_types/:pageId/:typeId?/:imageId?'
          component={MultiTypes}
        />
        <Route
          exact
          path='/multiple_types_n_images/:pageId/:typeId?/:imageId?/:caroFlag?'
          component={MultiTypesImg}
        />
        <Route exact path='/single_image/:pageId/:typeId?' component={Single} />
        <Route exact path='/vip/:pageId' component={Vip} />
        <Route path='/:type?/:pageId?/:typeId?' component={Footer} />
      </Suspense>
    </Router>
  );
}

export default App;
